
import { Fancybox } from "@fancyapps/ui";
import { Carousel } from "@fancyapps/ui";
import "@fancyapps/ui/dist/carousel/carousel.css";
import callback from "@/mixins/callback";


import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
	mixins: [callback],
	data() {
		return {
			mainCarousel: null,
			activeSlide: 1
		}
	},
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		url: String,
		offer: {
			type: Object | null,
			default: null
		},
		buttons: {
			type: Boolean,
			default: true
		},
		vin: {
			type: Boolean,
			default: true
		},
		activeFancybox: {
			type: Boolean,
			default: false
		},
		modal: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			likesArray: 'favorite/favorite/likesArray',
			currentCar: 'modal/modal-choose/currentCar',
			settings: 'settings/settings/settings',
			storeOffer: 'catalog/catalog-cars/offer',
			isNight: "settings/settings/isNight",
		}),

		currentOffer() {
			return this.offer
		},
		device() {
			return this.$device.isMobile ? this.$device.isMobile : false
		}

	},
	methods: {
		...mapActions({
			liked: 'favorite/favorite/liked',
			openModal: 'modal/modal-main/openModal',
			closeModal: 'modal/modal-main/closeModal',

		}),
		...mapMutations({
			setCarPageLoaded: 'catalog/catalog-cars/SET_CAR_PAGE_LOADED',
			setOffer: 'catalog/catalog-cars/SET_OFFER',

		}),
		// async callback() {
		// 	let payload = {
		// 		modal_data: { title: 'Обратная связь', sub_title: 'Мы работаем с 9:00 до 21:00.', text: 'Оставьте заявку, и мы свяжемся с Вами в рабочее время.' },
		// 		modal_component: 'modal-timer',
		// 		modal_title: 'ЗАЯВКА НА АВТОКРЕДИТ',
		// 		modal_sub_title: {}
		// 	}
		// 	this.openModal(payload)
		// },
		hoverItemAction(number) {
			this.mainCarousel && this.mainCarousel.slideTo(number)
			this.activeSlide = number
		},
		async like() {
			await this.liked(this.offer.external_id)
		},
		async autoteka(carInfo) {

			let payload = {
				modal_data: carInfo,
				modal_component: 'modal-autoteka',
				modal_title: 'Отчет по акутальным базам РФ от ' + carInfo.createdAt,
				modal_sub_title: carInfo.name
			}
			this.openModal(payload)
		},
		initSliders() {
			if (!this.$device.isMobile) {
				this.mainCarousel = new Carousel(document.querySelector(`#mainCarousel${this.offer.id}`), {
					Dots: {
						dynamicFrom: 5
					},
					Navigation: false,
					on: {
						init: () => {
							this.$emit('sliderInit')
						},

					},
					// dragFree:true,
					// Navigation: {
					// 	container: "f-carousel__nav",
					// 	// button: "f-button",
					// 	nextTpl: `<div data-carousel-next="true" class="swiper-button__arrow swiper-button__arrow--right swiper-button__arrow--mini" >
					// 		<div class="arrow"></div>
					// 	</ div>`,
					// 	prevTpl: `<div data-carousel-prev="true" class="swiper-button__arrow swiper-button__arrow--left swiper-button__arrow--mini" >
					// 		<div class="arrow"></div>
					// 	</ div>`,
					// },

				});

			} else {
				this.$emit('sliderInit')
			}

			this.setCarPageLoaded(true)
		}
	},

	mounted() {
		// if (this.activeFancybox)
		// 	Fancybox.bind(`[data-fancybox="gallery-${this.offer.id}${this.modal ? `-modal` : ''}"]`, {
		// 		groupAll: true,
		// 	})

		// if (!this.$device.isMobile)
		this.initSliders()
	},
	watch: {
		"$device.isMobile"(value) {
			setTimeout(()=>this.initSliders())

		},
		offer() {
			// if (this.activeFancybox)
			// 	Fancybox.bind(`[data-fancybox="gallery-${this.offer.id}${this.modal ? `-modal` : ''}"]`, {
			// 		groupAll: true,
			// 	})
		}
	}
}
