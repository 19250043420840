
export default {
	props: {
		active: {
			type: Boolean,
			default: false
		},
		mini: {
			type: Boolean,
			default: false
		}
	}
}
